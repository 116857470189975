import { Analytics } from "../utils/Analytics"
import { getLocalData, setLocalData } from "../utils/LocalDataStorage"
import { AppData, DraftNoteData, LocalStorageKey, Note, SetAppData } from "./types"

export function setupDraftNote(data: AppData, setData: SetAppData) {
  function setDraftNote(note: Note) {
    setData("draftNote", {
      id: note.id,
      lexicalContent: note.lexicalContent,
    })
    Analytics.track(Analytics.Event.noteEditingOpened)
  }

  function saveToLocalStorage() {
    setLocalData(LocalStorageKey.DraftNote, data.draftNote)
  }

  function updateDraftContent(lexicalContent: string) {
    setData("draftNote", "lexicalContent", lexicalContent)

    saveToLocalStorage()
  }

  function clearDraftNote() {
    setData("draftNote", { lexicalContent: undefined, id: undefined })

    saveToLocalStorage()
  }

  return { setDraftNote, updateDraftContent, clearDraftNote }
}

export function initialDraftNote(): DraftNoteData {
  return getLocalData(LocalStorageKey.DraftNote) ?? { lexicalContent: undefined }
}
