import { A, Navigate, useNavigate } from "@solidjs/router"
import { AuthError } from "firebase/auth"
import { Component, Show, createSignal, onMount } from "solid-js"
import { useAppData } from "../../data/AppData"
import { hasPendingLocalNotesMigration } from "../../data/migrations/localStorageNotesMigration"
import { addOnboardingNote } from "../../data/onboardingNotes"
import { Analytics } from "../../utils/Analytics"
import { Auth, SignUpDetails } from "../../utils/Auth"
import { formDataFrom } from "../../utils/Form"
import { AuthScreen } from "./AuthScreen"
import styles from "./AuthScreen.module.scss"
import { EmailInput, NameInput, PasswordInput } from "./Inputs"

export const SignUpScreen: Component = () => {
  const [validating, setValidating] = createSignal(false)

  const [nameErrorMessage, setNameErrorMessage] = createSignal<string>()
  const [emailErrorMessage, setEmailErrorMessage] = createSignal<string>()
  const [passwordErrorMessage, setPasswordErrorMessage] = createSignal<string>()
  const [formErrorMessage, setFormErrorMessage] = createSignal<string>()

  const navigate = useNavigate()
  const [data, { saveNote }] = useAppData()

  const hasAnonymousAccount = () => !!data.user?.isAnonymous
  const userAlreadyHasAccount = () => data.user && !data.user?.isAnonymous

  showNotesMigrationAlert()

  const validateAndSignUp = async (form: HTMLFormElement) => {
    setValidating(true)
    setFormErrorMessage()
    setNameErrorMessage()
    setEmailErrorMessage()
    setPasswordErrorMessage()

    if (form.reportValidity()) {
      try {
        const signUpDetails = formDataFrom<SignUpDetails>(form)
        if (hasAnonymousAccount()) signUpDetails.anonymousId = data.user?.id

        await Auth.signUpWithEmail(signUpDetails)

        addOnboardingNote(saveNote)

        navigate("/")
      } catch (error) {
        // See https://firebase.google.com/docs/auth/admin/errors
        switch ((error as AuthError)?.code) {
          case "auth/email-already-in-use":
          case "auth/credential-already-in-use":
          case "auth/account-exists-with-different-credential": {
            setFormErrorMessage(
              "An account with this email already exists. Try logging in instead.",
            )
            break
          }
          case "auth/invalid-display-name": {
            setNameErrorMessage("The provided name is invalid.")
            break
          }
          case "auth/invalid-email": {
            setEmailErrorMessage("Your email doesn't look quite right.")
            break
          }
          case "auth/invalid-password": {
            setPasswordErrorMessage("Your password must be a string with at least six characters.")
            break
          }
          case "auth/network-request-failed": {
            setFormErrorMessage("There was an issue connecting. Please try again later.")
            break
          }
          default:
            setFormErrorMessage(
              (error as AuthError)?.message ?? "Hmmm something went wrong. Try it again.",
            )
            Analytics.error(new Error(`Unknown sign up error`, { cause: error }))
        }
      }
    }
    setValidating(false)
  }

  const description = () =>
    hasAnonymousAccount()
      ? "Access your current and future notes on any device."
      : "Access your notes on any device."
  // Syncs your notes to your account so you can access them on any device.
  // Access your notes on any device. Don't worry any notes you've created will sync to your profile.

  return (
    <Show when={!userAlreadyHasAccount()} fallback={<Navigate href="/" />}>
      <AuthScreen canSkip={!hasAnonymousAccount()} canGoBack={hasAnonymousAccount()}>
        <form
          novalidate
          class={styles.form}
          onSubmit={(event) => {
            event.preventDefault()
            if (!validating()) validateAndSignUp(event.currentTarget)
          }}
        >
          <h1>Create your account</h1>
          <p class={styles.description}>{description()}</p>

          <NameInput
            validating={validating()}
            requiredError="What should we call you?"
            maxLengthError="Truly sorry, but you'll need to limit your name to 60 characters."
            errorOverride={nameErrorMessage()}
          />
          <EmailInput
            validating={validating()}
            requiredError="You'll need this to login, and to reset your password if you forget it."
            typeError="Your email doesn't look quite right."
            errorOverride={emailErrorMessage()}
          />
          <PasswordInput
            validating={validating()}
            requiredError="You're going to need this."
            minLengthError="You'll need at least 6 characters to be secure enough."
            maxLengthError="Too secure! You'll have to limit the password to 256 characters."
            errorOverride={passwordErrorMessage()}
          />

          {formErrorMessage() && <p class={styles.error}>{formErrorMessage()}</p>}

          <p class={styles.finePrint}>
            By using Jotik, you agree to our <A href="/terms-and-conditions">Terms & Conditions</A>{" "}
            and <A href="/privacy-policy">Privacy Policy</A>.
          </p>

          <button type="submit" disabled={validating()}>
            Create your account
          </button>

          <Show when={!hasAnonymousAccount()}>
            <p class={styles.altAuth}>
              Joined us before? <A href="/login">Login</A>
            </p>
          </Show>
        </form>
      </AuthScreen>
    </Show>
  )
}

function showNotesMigrationAlert() {
  onMount(function alertAboutNotesMigration() {
    if (hasPendingLocalNotesMigration()) {
      setTimeout(
        // `alert` is UI blocking, so the timeout allows the page to load first
        () =>
          alert(
            "Hi friend 👋\n\nJust letting you know all your existing notes are still safe and sound inside Jotik. Create an account (or skip) and you'll see them again.",
          ),
        100,
      )
    }
  })
}
