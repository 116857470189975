import { StoreSetter } from "solid-js/store"
import { AppData, SetAppData } from "./types"

export function setupFilters(data: AppData, setData: SetAppData) {
  function search(term: StoreSetter<string, ["searchTerm", "filters"]>) {
    setData("filters", "searchTerm", term)
  }

  return { search }
}
