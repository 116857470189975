import { useNavigate } from "@solidjs/router"
import { AuthError } from "firebase/auth"
import { Component, createResource, createSignal } from "solid-js"
import { Analytics } from "../../utils/Analytics"
import { Auth } from "../../utils/Auth"
import { formDataFrom } from "../../utils/Form"
import { AuthScreen } from "./AuthScreen"
import styles from "./AuthScreen.module.scss"
import { PasswordInput } from "./Inputs"

type PasswordResetScreenProps = {
  code: string
  continueUrl?: string
}

export const ResetPasswordScreen: Component<PasswordResetScreenProps> = (props) => {
  const [validating, setValidating] = createSignal(false)
  const [errorMessage, setErrorMessage] = createSignal<string>()
  const navigate = useNavigate()

  const [codeInfo] = createResource(() => props.code, Auth.checkLinkCode)

  const setPassword = async (form: HTMLFormElement) => {
    setValidating(true)
    setErrorMessage()

    if (form.reportValidity()) {
      const email = codeInfo()?.data.email ?? ""
      const { password } = formDataFrom<{ password: string }>(form)

      try {
        await Auth.resetPassword(props.code, password)
        await Auth.signInWithEmail({ email, password })
      } catch (error) {
        if ((error as AuthError)?.code === "auth/weak-password") {
          setErrorMessage("The password is too weak.")
        } else {
          setErrorMessage("Hmmm something went wrong. Try it again.")
          Analytics.log(`Unknown reset password error: ${error}`, "error")
        }
      }

      navigate(props.continueUrl ?? "/")
    }
    setValidating(false)
  }

  return (
    <AuthScreen>
      <form
        novalidate
        class={styles.form}
        onSubmit={(event) => {
          event.preventDefault()
          if (!validating()) setPassword(event.currentTarget)
        }}
      >
        <h1>Set a new password</h1>

        <PasswordInput
          validating={validating()}
          requiredError="What's your new password going to be?"
          placeholder="New password"
        />

        {errorMessage() && <p class={styles.error}>{errorMessage()}</p>}

        <button type="submit" disabled={codeInfo.loading || validating()}>
          Reset your password
        </button>
      </form>
    </AuthScreen>
  )
}
