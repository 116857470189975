import { For } from "solid-js"
import { useAppData } from "../data/AppData"
import { es } from "../utils/style"
import { Button } from "./Button"
import { Scrollable } from "./Scrollable"
import styles from "./TagSelector.module.scss"

type TagSelectorProps = {
  tagSearch: string
  onTagSelect: (tag: string) => void
  class?: string
  includeSearchAsTag?: boolean
  showAllWhenEmpty?: boolean
}

export const TagSelector = (props: TagSelectorProps) => {
  const [data] = useAppData()

  const filteredTags = (): string[] => {
    const tagSearch = props.tagSearch
    if (tagSearch === null) return []

    let fTags = [...data.tags.byCumulativeUsage.map(({ tag }) => tag)]
    if (tagSearch !== "") fTags = fTags.filter((tag) => tag.startsWith(tagSearch)).slice(0, 20)

    if (props.includeSearchAsTag && tagSearch.length > 0 && !fTags.includes(tagSearch))
      fTags.push(tagSearch)

    if (props.showAllWhenEmpty && fTags.length === 0)
      fTags = [...data.tags.byCumulativeUsage.map(({ tag }) => tag)]

    return fTags
  }

  return (
    <Scrollable horizontal class={es(styles.tags, props.class)}>
      <For each={filteredTags()} fallback={<NoTag>{props.tagSearch}</NoTag>}>
        {(tag) => (
          <Button title={`Enter tag #${tag}`} onClick={() => props.onTagSelect(tag)}>
            #{tag}
          </Button>
        )}
      </For>
    </Scrollable>
  )
}

export const NoTag = (props: { children: string }) => (
  <span>
    No <strong>#{props.children.trim()}</strong> tag exists
  </span>
)
