import {
  init as amplitudeInit,
  reset as amplitudeReset,
  setOptOut as amplitudeDisable,
  setUserId as amplitudeSetUserId,
  track as amplitudeTrack,
} from "@amplitude/analytics-browser"
import {
  BrowserTracing,
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  init as sentryInit,
  setUser as sentrySetUser,
  SeverityLevel,
} from "@sentry/browser"
import { version as appVersion } from "../../package.json"
import { LocalStorageKey, User } from "../data/types"
import Event from "./AnalyticsEvents"
import { getLocalData } from "./LocalDataStorage"

const LOCAL_ANALYTICS_ENABLED = false
const ANALYTICS_ENABLED = import.meta.env.PROD || LOCAL_ANALYTICS_ENABLED

/** Initialize Sentry and Amplitude */
async function initialize() {
  initializeSentry()
  initializeAmplitude()
  console.log(`Analytics initialized: ${import.meta.env.MODE} ${appVersion}`)
}

function initializeSentry() {
  sentryInit({
    dsn: import.meta.env.APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: import.meta.env.MODE,
    release: `jotik-app@${appVersion}`,
    enabled: ANALYTICS_ENABLED,
  })
}

async function initializeAmplitude() {
  const user = getLocalData<User>(LocalStorageKey.User)
  const API_KEY = import.meta.env.APP_AMPLITUDE_API_KEY

  amplitudeDisable(!ANALYTICS_ENABLED)
  await amplitudeInit(API_KEY, user?.id, { appVersion }).promise
}

/** Identify the user in Amplitude and Sentry */
function setUserId(userId: string) {
  amplitudeSetUserId(userId)
  sentrySetUser({ id: userId })
  console.log("Analytics set user ID:", userId)
}

/** Unset the user identified in Amplitude and Sentry. */
function unsetUser(): void {
  amplitudeReset()
  sentrySetUser(null)
  console.log("Analytics unset user")
}

export type Properties = Record<string, string | number | string[] | number[] | boolean>

/** Track an event in Amplitude */
function track(event: Event, properties?: Properties) {
  amplitudeTrack(event, properties)
  console.log("Analytics event:", event, properties ?? "")
}

/** Track an error in Sentry */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function error(error: any) {
  sentryCaptureException(error)
  console.log("Analytics error:", error)
}

/** Log a message in Sentry */
function log(message: string, severity?: SeverityLevel) {
  sentryCaptureMessage(message, severity)
  console.log("Analytics log:", message)
}

export const Analytics = {
  initialize,
  setUserId,
  unsetUser,
  track,
  error,
  log,
  Event: Event,
}
