import { useNavigate, useSearchParams } from "@solidjs/router"
import { AuthError } from "firebase/auth"
import { Component, createSignal } from "solid-js"
import { Auth } from "../../utils/Auth"
import { formDataFrom } from "../../utils/Form"
import { AuthScreen } from "./AuthScreen"
import styles from "./AuthScreen.module.scss"
import { EmailInput } from "./Inputs"

export const ForgotPasswordScreen: Component = () => {
  const [searchParams] = useSearchParams<{ email: string }>()

  const [validating, setValidating] = createSignal(false)
  const [errorMessage, setErrorMessage] = createSignal<string>()
  const navigate = useNavigate()

  const sendReset = async (form: HTMLFormElement) => {
    setValidating(true)
    setErrorMessage()

    if (form.reportValidity()) {
      try {
        const { email } = formDataFrom<{ email: string }>(form)

        await Auth.sendResetPasswordEmail(email)

        alert("Email sent. Check your inbox for a link to reset your password.")
        navigate("/")
      } catch (error) {
        if ((error as AuthError)?.code === "auth/user-not-found")
          setErrorMessage("No account with this email exists. Try a different account instead.")
        else setErrorMessage("Hmmm something went wrong. Try it again.")
      }
    }
    setValidating(false)
  }

  return (
    <AuthScreen canGoBack>
      <form
        novalidate
        class={styles.form}
        onSubmit={(event) => {
          event.preventDefault()
          if (!validating()) sendReset(event.currentTarget)
        }}
      >
        <h1>Forgot your password?</h1>
        <p class={styles.description}>Not to worry, we'll email you a link to reset it.</p>

        <EmailInput
          validating={validating()}
          requiredError="Email cannot be blank."
          placeholder="Email"
          value={searchParams.email}
        />

        {errorMessage() && <p class={styles.error}>{errorMessage()}</p>}

        <button type="submit" disabled={validating()}>
          Send password reset email
        </button>
      </form>
    </AuthScreen>
  )
}
