import { IconTypes } from "solid-icons"
import { BiRegularDotsHorizontalRounded, BiRegularEdit, BiRegularTrash } from "solid-icons/bi"
import { createSignal, Show } from "solid-js"
import { Dynamic, Portal } from "solid-js/web"
import styles from "./NoteContextMenu.module.scss"

type ContextMenuProps = {
  onEditClick: () => void
  onCompleteAllClick: () => void
  onIncompleteAllClick: () => void
  onDeleteClick: () => void
}

export const ContextMenu = (props: ContextMenuProps) => {
  const [isOpen, setIsOpen] = createSignal(false)

  const edit = () => {
    props.onEditClick()
    navigator.vibrate?.(10)
  }

  const completeAll = () => {
    props.onCompleteAllClick()
    navigator.vibrate?.(10)
  }

  const incompleteAll = () => {
    props.onIncompleteAllClick()
    navigator.vibrate?.(10)
  }

  const deleteNote = () => {
    props.onDeleteClick()
    navigator.vibrate?.(50)
  }

  return (
    <>
      <div
        title="Open note menu"
        class={styles.contextMenuButton}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <BiRegularDotsHorizontalRounded />
      </div>

      <Show when={isOpen()}>
        <Portal>
          <div class={styles.contextMenuModalBackdrop} onClick={() => setIsOpen(false)}>
            <div class={styles.contextMenuModal}>
              <ContextMenuItem icon={BiRegularEdit} onClick={edit}>
                Edit
              </ContextMenuItem>
              {/* <ContextMenuItem icon={BiRegularCheckSquare} onClick={completeAll}>
                Mark all complete
              </ContextMenuItem>
              <ContextMenuItem icon={BiRegularSquare} onClick={incompleteAll}>
                Mark all incomplete
              </ContextMenuItem> */}
              <hr />
              <ContextMenuItem icon={BiRegularTrash} onClick={deleteNote}>
                Delete
              </ContextMenuItem>
            </div>
          </div>
        </Portal>
      </Show>
    </>
  )
}

type ContextMenuItemProps = {
  icon: IconTypes
  onClick: () => void
  children: string
}
const ContextMenuItem = (props: ContextMenuItemProps) => (
  <div class={styles.contextMenuItem} onClick={() => props.onClick()}>
    <div>
      <Dynamic component={props.icon} />
    </div>
    <div>{props.children}</div>
  </div>
)
