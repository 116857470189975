import { JSX, ParentProps } from "solid-js"

type ButtonProps = ParentProps<{
  onClick?: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>
  disabled?: boolean
  class?: string
  title?: string
}>
export const Button = (props: ButtonProps) => (
  <button
    class={props.class}
    onPointerDown={(event) => event.preventDefault()}
    onMouseDown={(event) => event.preventDefault()}
    onClick={(event) => typeof props.onClick === "function" && props.onClick(event)}
    disabled={props.disabled}
    title={props.title}
  >
    {props.children}
  </button>
)
