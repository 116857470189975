import { useAppData } from "../data/AppData"
import { NoteEditor } from "./editor/NoteEditor"
import { NotesFeed } from "./feed/NotesFeed"
import { NotesHeader } from "./header/NotesHeader"
import styles from "./NotesScreen.module.scss"

export const NotesScreen = () => {
  const [data] = useAppData()

  return (
    <div class={styles.notesScreen}>
      <NotesHeader />
      <NotesFeed notes={data.notes.filtered} />
      <NoteEditor />
    </div>
  )
}
