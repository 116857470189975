import { doc, setDoc } from "firebase/firestore"
import { produce } from "solid-js/store"
import { db } from "../firebase"
import { Analytics } from "../utils/Analytics"
import { jsonDateReviver } from "../utils/jsonReviver"
import { notesConverter } from "./notesData"
import { ActionType, AppData, Note, SetAppData } from "./types"

export function setupRecentActions(data: AppData, setData: SetAppData) {
  function addAction(type: ActionType, data: { note: Note }) {
    const unwrappedData = JSON.parse(JSON.stringify(data), jsonDateReviver)
    setData(
      "recentActions",
      produce((actions) => actions.push({ type, data: unwrappedData })),
    )
  }

  async function undoAction() {
    const action = data.recentActions[data.recentActions.length - 1]
    if (!action) return

    Analytics.track(Analytics.Event.undo, { undoAction: action.type })

    const note = action.data.note

    setDoc(doc(db, "notes", note.id).withConverter(notesConverter), note)

    setData(
      "recentActions",
      produce((actions) => actions.pop()),
    )
  }

  return { addAction, undoAction }
}
