import { onIdTokenChanged, updateProfile } from "firebase/auth"
import { onCleanup, onMount } from "solid-js"
import { auth } from "../firebase"
import { getLocalData, setLocalData } from "../utils/LocalDataStorage"
import { AppData, LocalStorageKey, SetAppData, UserData } from "./types"

export function setupUser(data: AppData, setData: SetAppData) {
  onMount(function syncUserFromAuth() {
    const unsubscribe = onIdTokenChanged(auth, (user) => {
      if (user) {
        // TODO get user data
        setData("user", {
          id: user.uid,
          name: user.displayName,
          image: user.photoURL,
          email: user.email,
          authProvider: user.providerId,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
          createdAt: new Date(user.metadata.creationTime!),
        })
      } else {
        setData("user", null)
      }

      setLocalData(LocalStorageKey.User, data.user)
    })

    onCleanup(unsubscribe)
  })

  function updateUser(profile: { name?: string | null; image?: string | null }) {
    if (auth.currentUser) {
      updateProfile(auth.currentUser, { displayName: profile.name, photoURL: profile.image }).then(
        () => auth.currentUser?.reload(),
      ) // Reload so listeners get updated profile
    } else {
      throw Error("updateUser was called before a user was authenticated")
    }
  }

  return { updateUser }
}

/** Gets the user data from localStorage so it's immediately available */
export function initialUserData(): UserData {
  return getLocalData(LocalStorageKey.User) ?? null
}
