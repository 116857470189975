import { useNavigate } from "@solidjs/router"
import { BiRegularSearch, BiRegularUndo, BiRegularUserCircle, BiRegularX } from "solid-icons/bi"
import { createEffect, createSignal, Show } from "solid-js"
import { Transition } from "solid-transition-group"
import { useAppData } from "../../data/AppData"
import { Analytics } from "../../utils/Analytics"
import { createShortcut } from "../../utils/KeyboardShortcuts"
import { es } from "../../utils/style"
import { getCurrentWord } from "../../utils/text"
import { TagSelector } from "../TagSelector"
import { Header, HeaderButton, HeaderRow } from "./Header"
import styles from "./NotesHeader.module.scss"

type InputEvent = globalThis.InputEvent & {
  currentTarget: HTMLInputElement
  target: Element
}

export const NotesHeader = () => {
  let inputRef: HTMLInputElement

  const [data, { search }] = useAppData()
  const [hasFocus, setHasFocus] = createSignal(false)
  const [tagSearch, setTagSearch] = createSignal("")

  createShortcut("ctrl+f, command+f", () => inputRef.focus())

  const onSearchInput = (event: InputEvent) => {
    const text = event.currentTarget.value
    const caretIndex = event.currentTarget.selectionStart ?? 0
    search(text)

    const { currentWord } = getCurrentWord(text, caretIndex)
    setTagSearch((currentWord[0] === "#" ? currentWord.slice(1) : currentWord).toLowerCase())
  }

  const completeTag = (tag: string) => {
    const caretIndex = inputRef?.selectionStart ?? 0
    const term = data.filters.searchTerm
    const { startIndex, currentWord } = getCurrentWord(term, caretIndex)
    const updatedSearch = `${term.slice(0, startIndex)}#${tag} ${term
      .slice(startIndex + currentWord.length)
      .trimStart()}`

    search(updatedSearch)
    setTagSearch("")
    Analytics.track(Analytics.Event.searchTagAutocompleted)
  }

  createEffect(function emitSearchAnalyticsEvent(hadFocus = false) {
    if (!hadFocus && hasFocus()) Analytics.track(Analytics.Event.searchStarted)
    return hasFocus()
  })

  const clear = () => {
    search("")
    setTagSearch("")
  }

  return (
    <Header hasRows>
      <HeaderRow>
        <section />

        <section class={styles.searchSection}>
          <label class={styles.search}>
            <div class={es(styles.icon, styles.searchIcon)}>
              <BiRegularSearch />
            </div>

            <input
              ref={(el) => (inputRef = el)}
              type="search"
              placeholder="Search"
              value={data.filters.searchTerm}
              onInput={onSearchInput}
              onFocus={() => setHasFocus(true)}
              onBlur={() => setHasFocus(false)}
            />

            <Show when={data.filters.searchTerm.length > 0}>
              <button
                title="Clear search"
                class={es(styles.icon, styles.clearSearch)}
                onClick={clear}
              >
                <BiRegularX />
              </button>
            </Show>
          </label>
        </section>

        <section>
          <UndoButton />
          <UserButton />
          {/* <FilterButton /> */}
        </section>
      </HeaderRow>

      <Transition name="open-fade">
        <Show when={hasFocus()}>
          <TagSelector
            class={styles.tags}
            tagSearch={tagSearch()}
            onTagSelect={completeTag}
            showAllWhenEmpty
          />
        </Show>
      </Transition>
    </Header>
  )
}

const UserButton = () => {
  const navigate = useNavigate()

  return (
    <HeaderButton
      onClick={() => navigate("/preferences")}
      title="Preferences"
      icon={BiRegularUserCircle}
    />
  )
}

// const FilterButton = () => {
//   const [data, setFilter] = useFilterStore()
//   const isOn = () => filter.filter === "incomplete"
//   const toggleFilter = () => {
//     setFilter("filter", (prevFilter) => (prevFilter === "incomplete" ? null : "incomplete"))
//   }

//   return (
//     <button
//       class={styles.filter}
//       onClick={toggleFilter}
//       title={isOn() ? "Show all notes" : "Show incomplete notes only"}
//     >
//       <Show when={isOn()} fallback={<BsListTask />}>
//         <BsListCheck />
//       </Show>
//     </button>
//   )
// }

const UndoButton = () => {
  const [data, { undoAction }] = useAppData()
  const [highlight, setHighlight] = createSignal(false)
  const hasUndoableActions = () => data.recentActions.length > 0

  createEffect<number>(function highlightFor3Secs(numActions = 0) {
    if (data.recentActions.length > numActions) {
      setHighlight(true)
      setTimeout(() => setHighlight(false), 3000)
    }
    return data.recentActions.length
  })

  return (
    <Show when={hasUndoableActions()}>
      <HeaderButton
        class={styles.undo}
        classList={{ [styles.highlight]: highlight() }}
        onClick={() => undoAction()}
        title="Undo"
        icon={BiRegularUndo}
      />
    </Show>
  )
}
