import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  FirestoreDataConverter,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore"
import { trace } from "firebase/performance"
import Fuse from "fuse.js"
import { createEffect, onCleanup } from "solid-js"
import { reconcile } from "solid-js/store"
import { dateFrom, db, perf, timestampFrom } from "../firebase"
import { Analytics } from "../utils/Analytics"
import { debounce } from "../utils/debounce"
import { getLocalData, setLocalData } from "../utils/LocalDataStorage"
import { removeTags, tagsIn } from "./tagsUsageData"
import { AppData, LocalStorageKey, Note, SetAppData } from "./types"

const loadingNotesTrace = trace(perf, "Loading initial notes")
let hasLoaded = false

export type SaveNote = Pick<Note, "lexicalContent" | "plainContent"> & { id?: string }

export function setupNotes(data: AppData, setData: SetAppData) {
  loadingNotesTrace.start()

  const noteDoc = (id: string) => doc(db, "notes", id).withConverter(notesConverter)
  const notesCollection = () => collection(db, "notes").withConverter(notesConverter)

  createEffect(function syncNotesFromFirestore() {
    if (data.user?.id) {
      const notesQuery = query(
        collection(db, "notes"),
        where("editors", "array-contains", data.user.id),
        orderBy("createdAt", "desc"),
      ).withConverter(notesConverter)

      const unsubscribe = onSnapshot(notesQuery, (snapshot: QuerySnapshot<Note>) => {
        const notes = snapshot.docs.map((doc) => doc.data())
        setData("notes", "all", reconcile(notes, { key: "id" }))
        setLatestNotes(notes)
        if (!hasLoaded) {
          loadingNotesTrace.stop()
          hasLoaded = true
        }
      })
      onCleanup(unsubscribe)
    } else {
      setData("notes", "all", [])
    }
  })

  /** Create or update a Note after editing it in the note editor. */
  function saveNote(note: SaveNote) {
    const owner = data.user?.id
    if (!owner) throw Error("saveNote was called before a user was authenticated")

    const tags = tagsIn(note.plainContent!)
    const now = new Date()
    const allCompletedAt = areAllPointsCompleted(note.lexicalContent!) ? now : null
    const editors = editorsSKShare(owner, tags)

    // Don't await as the promise will never resolve when offline
    if (note.id) {
      updateDoc(noteDoc(note.id), {
        lexicalContent: note.lexicalContent,
        plainContent: note.plainContent,
        editors,
        tags,
        updatedAt: now,
        allCompletedAt,
      })
    } else {
      addDoc(notesCollection(), {
        id: "", // dw, notesConverter removes this
        lexicalContent: note.lexicalContent,
        plainContent: note.plainContent,
        tags,
        owner,
        editors,
        createdAt: now,
        updatedAt: now,
        allCompletedAt,
        archivedAt: null,
        serverSyncedAt: null,
      })
    }
  }

  function archiveNote(id: string) {
    const now = new Date()
    updateDoc(noteDoc(id), {
      updatedAt: now,
      archivedAt: now,
    })
  }

  function deleteNote(note: Note) {
    const allPointsCompleted = note.allCompletedAt !== undefined
    deleteDoc(noteDoc(note.id))

    Analytics.track(Analytics.Event.noteDeleted, { allPointsCompleted })
  }

  function completeAllNoteCheckboxes(note: Note) {
    if (note.allCompletedAt) return

    const now = new Date()
    const lexicalContent = note.lexicalContent?.replace('"checked":false', '"checked":true')

    updateDoc(noteDoc(note.id), {
      lexicalContent,
      allCompletedAt: now,
      updatedAt: now,
    })
  }

  function incompleteAllNoteCheckboxes(note: Note) {
    const now = new Date()
    const lexicalContent = note.lexicalContent?.replace('"checked":true', '"checked":false')

    updateDoc(noteDoc(note.id), {
      lexicalContent,
      allCompletedAt: null,
      updatedAt: now,
    })
  }

  return {
    saveNote,
    archiveNote,
    deleteNote,
    completeAllNoteCheckboxes,
    incompleteAllNoteCheckboxes,
  }
}

const fuzzySearchOptions: Fuse.IFuseOptions<Note> = {
  includeScore: false,
  shouldSort: false,
  ignoreLocation: true,
  useExtendedSearch: true,
  threshold: 0.35,
  distance: 1000,
  keys: ["plainContent"],
}

export function filterNotes(data: AppData): Note[] {
  let filteredNotes = data.notes.all

  // TODO: Filter based on the selected filters

  // Filter out the draft note since it's shown in the editor
  const draftNoteId = data.draftNote.id
  if (draftNoteId) filteredNotes = filteredNotes.filter((note) => note.id !== draftNoteId)

  // Temp fix to prevent notes without lexical content from erroring on render
  filteredNotes = filteredNotes.filter((note) => note.lexicalContent?.length)

  // Filter based on the search
  const searchTerm = data.filters.searchTerm.trim()
  if (searchTerm.length) {
    // See https://fusejs.io/examples.html#extended-search
    const matchTags = tagsIn(data.filters.searchTerm)
      .map((tag) => `|'#${tag}`)
      .join("")
    const matchText = removeTags(data.filters.searchTerm)
    const match = `${matchText}${matchTags}`.trim()

    const notesFuse = new Fuse(filteredNotes, fuzzySearchOptions)
    filteredNotes = notesFuse.search(match).map((result) => result.item)
  }

  return filteredNotes
}

function areAllPointsCompleted(lexicalContent: string): boolean {
  return lexicalContent.includes('"checked":true') && !lexicalContent.includes('"checked":false')
}

export function initialNotes(): Note[] {
  return getLocalData<Note[]>(LocalStorageKey.LatestNotes) ?? []
}

const setLatestNotes = debounce((notes: Note[]) => {
  const latestNotes = notes.slice(0, 10)
  setLocalData(LocalStorageKey.LatestNotes, latestNotes)
}, 1200)

/** Converts a `Note` to a Firestore note doc, and vice-versa. */
export const notesConverter: FirestoreDataConverter<Note> = {
  toFirestore: (note) => ({
    ...(note.points ? { points: note.points } : {}),
    lexicalContent: note.lexicalContent,
    plainContent: note.plainContent,
    tags: note.tags,
    owner: note.owner,
    editors: note.editors,
    createdAt: timestampFrom(note.createdAt),
    updatedAt: timestampFrom(note.updatedAt),
    allCompletedAt: timestampFrom(note.allCompletedAt),
    archivedAt: timestampFrom(note.allCompletedAt),
    serverSyncedAt: serverTimestamp(),
  }),
  fromFirestore: (snapshot, options) => {
    const dbNote = snapshot.data(options)
    return {
      id: snapshot.id,
      owner: dbNote.owner,
      editors: dbNote.editors,
      points: dbNote.points,
      lexicalContent: dbNote.lexicalContent,
      plainContent: dbNote.plainContent,
      tags: dbNote.tags,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      createdAt: dateFrom(dbNote.createdAt)!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      updatedAt: dateFrom(dbNote.updatedAt)!,
      allCompletedAt: dateFrom(dbNote.allCompletedAt),
      archivedAt: dateFrom(dbNote.archivedAt),
      serverSyncedAt: dateFrom(dbNote.serverSyncedAt),
    }
  },
}

const SK_IDS = [import.meta.env.APP_SCOTT_ID, import.meta.env.APP_SARAH_ID]
const SK_SHARED_TAGS = ["shopping", "house"]

function editorsSKShare(owner: string, tags: string[]): string[] {
  return SK_IDS.includes(owner) && SK_SHARED_TAGS.some((tag) => tags?.includes(tag))
    ? SK_IDS
    : [owner]
}
